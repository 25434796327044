<template>
  <div class="base-box">
    <UploadInfoCom
      @uploadSuccess="uploadSuccessCallBack"
      />
    <SearchInput
      @searchInput="searchInput"
      />
    <TableList
      :dataList="dataList"
      :total="total"
      :pageNum="pageNum"
      :pageSize="pageSize"
      @currentPageChange="currentPageChange"
      @pageSizeChange="pageSizeChange"
      />
  </div>
</template>

<script>
import dataMoveMixin from '@/api/dataMoveMixin'
import UploadInfoCom from '@/components/UploadInfoCom/Index.vue'
import TableList from '@/components/UploadInfoCom/TableList.vue'
import SearchInput from '@/components/SearchInput/Index.vue'
export default {
  name: "baseData",
  mixins: [dataMoveMixin],
  data() {
    return{
      unSyncColumn: [
        {
          label: "老成本主体名称",
          prop: "costEnterpriseName",
          minWidth: 190
        },
        {
          label: "老成本主体id",
          prop: "costEnterpriseId",
          minWidth: 190
        },
        {
          label: "老成本NC编码",
          prop: "costEnterpriseNccode",
          minWidth: 190
        },
        {
          label: "投管主体名称",
          prop: "investEnterpriseName",
          minWidth: 190
        },
        {
          label: "投管主体id",
          prop: "investEnterpriseId",
          minWidth: 190
        },
        {
          label: "投管NC编码",
          prop: "investEnterpriseNccode",
          minWidth: 170
        },
      ],
      dataList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      companyName: ''
    }
  },
  components: {
    UploadInfoCom,
    TableList,
    SearchInput
  },
  methods: {
    uploadSuccessCallBack() {
      this.pageNum = 1
      this.getQueryCompany()
    },
    // 分页-切换页码
    currentPageChange(val) {
      this.pageNum = val
      this.getQueryCompany()
    },
    // 分页-切换pageSize
    pageSizeChange(val) {
      this.pageNum = 1
      this.pageSize = val
      this.getQueryCompany()
    },
    // 搜索
    searchInput(val) {
      this.companyName = val
      this.pageNum = 1
      this.getQueryCompany()
    },
    init() {
      this.getQueryCompany()
    },
    getQueryCompany() {
      const obj = {
        params: {
          "companyName": this.companyName,
          "pageNum": this.pageNum,
          "pageSize": this.pageSize
        }
      }
      return this.$request.post(this.URL.queryCompany, obj).then(res => {
        if (res.code === '200') {
          this.total = Number(res.data.total)
          this.dataList = res.data.list
        }
      })
    }
  },
  mounted() {
    this.init()
  }
};
</script>
<style lang="less" scoped>

</style>